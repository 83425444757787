import React from "react";
import LazyLoad from 'react-lazyload';

const GalleryCard = ({ data, onImageClick }) => {
    const { img, tag } = data;

    return (
        <div className={`grid-item ${tag} width-50`} onClick={() => onImageClick(img)}>
            <div className="thumb">
                <LazyLoad height={200} offset={100} once>
                    <img className="item_image" loading="lazy" src={img} alt="" />
                </LazyLoad>
            </div>
        </div>
    );
};

export default GalleryCard;
