import React from 'react';

const UpcomingEventsItem = (props) => {
    const formattedEventTitle = props.name.replace(/ /g, '-');
    const link = '/event-details/' + formattedEventTitle.toLowerCase()
    return (
        <div className="thumb">
            <a href={link}>
            <img className="item_image" src={props.img} alt="img" />
            <div className="works-info">
                <div className="label-text">
                    <h6>{props.name}</h6>
                    <h5>{props.details}</h5>
                </div>
            </div>
            </a>
        </div>

    );
};

export default UpcomingEventsItem;