import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EventDetailsInner from '../../components/ProjectElements/EventDetailsInner';
import EventSidebar from '../../components/EventSidebar';
import SectionGridLines from "../../components/SectionGridLines";
import SectionHeader from "../../components/SectionHeader";
import PageHeader from "../../components/PageHeader";
import FullscreenImage from '../../components/FullscreenImage'; // Path to your FullscreenImageOverlay component

const EventDetailsContainer = () => {
    const { eventTitle } = useParams();
    const formattedEventTitle = eventTitle.replace(/-/g, ' ');
    document.title = 'Vox Dei - ' + formattedEventTitle.toUpperCase();
    
    const navigate = useNavigate();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [fullscreenImage, setFullscreenImage] = useState(null);

    const handleFullscreenImageOpen = (src) => {
        setFullscreenImage(src);
    };

    const handleFullscreenImageClose = () => {
        setFullscreenImage(null);
    };

    const eventsArray = [
        // {
        //     eventTitle: 'winter-camp-2024',
        //     eventDate: '01-03 Martie 2024',
        //     eventLocation: 'Casa Elim, Zărnești',
        //     eventDescription: '<p>Avem deja planuri pentru noul an!🔥</p><p>VoxDei organizează un weekend la munte, în perioada 01-03.03.2024 la Casa Elim, Zărnești.</p><p>🥁Așadar, anunțăm deschiderea înscrierilor! 🥁</p><p>Cum te poți înscrie? Achitând avansul nerambursabil de 200 RON, constul total fiind de 315 RON, la una dintre persoanele desemnate: @corneliu.baltes sau @i_cris</p><p>Va fi un weekend cu multe jocuri, distracție dar și cu un timp de worship, studiu și socializare!🏐🎶</p><p>Can\'t wait!🤩</p>',
        //     eventPoster: "/images/bg/2024/wintercamp-2024.webp",
        //     eventPictures: []
        // },
        {
            eventTitle: 'summer-camp-2025',
            eventDate: 'TBA',
            eventTime: 'TBA',
            eventLocation: 'TBA',
            eventDescription: 'TBA',
            eventPoster: "/images/bg/2025/summer-camp-2025.webp",
            eventPictures: []
        },
        {
            eventTitle: 'season-start-2024',
            eventDate: '22 octombrie 2024',
            eventTime: '19:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>Începem serile Vox Dei din 22 octombrie!<p>',
            eventPoster: "/images/bg/2024/season-start.webp",
            eventPictures: []
        },
        {
            eventTitle: 'unbound---empowered-by-faith',
            eventDate: '18-19 octombrie 2024',
            eventTime: '17:00 și 9:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>Într-o lume tot mai întunecata in care totul are de a face cu percepția ochilor, Credința este lumina care ne ghidează pașii în întuneric, iar umblarea prin credință înseamnă a merge înainte cu inima încrezătoare, știind că Dumnezeu ne poartă de grijă în fiecare clipă, chiar și atunci când nu vedem calea.</p><p>Încrederea în Dumnezeu ne dă curajul să pășim fără teamă, știind că promisiunile Sale sunt mai sigure decât orice vedem cu ochii.</p><style>  .unbound-button {    font-family: \'GFS Didot\' !important;    letter-spacing: 0.1rem !important;    margin-left: 10px;    padding: 25px;    border: 0;    border-radius: 7px;    color: #000;    background-color: #646464;    transition: background-color 0.3s ease, transform 0.3s ease;  }</style><form action="/unbound"><button class="unbound-button" type=\'submit\'><span class="link_text" >Înscriere</span></button></form>',
            eventPoster: "/images/bg/2024/unbound-2024.webp",
            eventPictures: []
        },
        {
            eventTitle: 'summer-camp-2024',
            eventDate: '05-10 august 2024',
            eventTime: '17:00',
            eventLocation: 'Vlăhița, Harghita',
            eventDescription: '<p>A VENIT VARAAA!!! 🤩 </p><p>Si cu asta și anunțul nostru oficial pentru tabăra de vară. </p>',
            eventPoster: "/images/bg/2024/summer-camp-2024.webp",
            eventPictures: []
        },
        {
            eventTitle: 'colindat-2023',
            eventDate: '24 decembrie 2023',
            eventTime: '19:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>"Astăzi, în cetatea lui David, vi s-a născut un Mântuitor, care este Hristos, Domnul.” Luca 2:11</p><p>Cum ne petrecem noi Crăciunul? Ducând vestea bună și sărbătorind nașterea Pruncului prin cântec.</p>',
            eventPoster: "/images/bg/2023/colindat-2023.webp",
            eventPictures: []
        },
        {
            eventTitle: 'unbound---empowered-by-scripture',
            eventDate: '13-14 octombrie 2023',
            eventTime: '17:00 și 9:00',
            eventLocation: 'Biserica Betania Sibiu',
            eventDescription: '<p>𝐘𝐞𝐬, 𝐢𝐭’𝐬 𝐡𝐚𝐩𝐩𝐞𝐧𝐢𝐧𝐠🥳</p><p>🥁Dăm startul înscrierilor pentru conferința UNBOUND! 🥁</p><p>Poți lua parte la experiența inedită oferită de această conferință astfel: accesezi link-ul din bio sau intri pe voxdei.ro/unbound și urmezi instrucțiunile pentru înscriere. Taxa de participare este de 30 RON. Odată înscris, te așteptăm pe 13-14 octombrie!</p><p>Rezervă-ți locul din timp!⏱️</p><style>  .unbound-button {    font-family: \'GFS Didot\' !important;    letter-spacing: 0.1rem !important;    margin-left: 10px;    padding: 25px;    border: 0;    border-radius: 7px;    color: #000;    background-color: #646464;    transition: background-color 0.3s ease, transform 0.3s ease;  }</style><form action="/unbound"><button class="unbound-button" type=\'submit\'><span class="link_text" >Înscriere</span></button></form>',
            eventPoster: "/images/bg/2023/unbound-2023.webp",
            eventPictures: []
        },
        {
            eventTitle: 'summer-camp-2023',
            eventDate: '21-26 august 2023',
            eventTime: '19:00',
            eventLocation: 'Vlăhița, Harghita',
            eventDescription: '<p>𝗔𝗥𝗘 𝗨 𝗥𝗘𝗔𝗗𝗬 𝗙𝗢𝗥 𝗦𝗢𝗠𝗘 𝗕𝗜𝗚 𝗡𝗘𝗪𝗦？</p><p>Da, 𝐕𝐨𝐱 𝐃𝐞𝐢 se pregătește de vacanță! Din acest motiv întâlnirile săptămânale ajung la final, dar noi tot ne vom revedea în această vară, pentru că:📢 anunțăm oficial deschiderea înscrierilor pentru 𝐒𝐮𝐦𝐦𝐞𝐫 𝐂𝐚𝐦𝐩 𝐕𝐨𝐱 𝐃𝐞𝐢🥳🥳</p><p>Dorim să repetăm experiența din anul anterior, de aceea, această tabără va avea loc în aceeași locație, și anume, în județul 𝐇𝐚𝐫𝐠𝐡𝐢𝐭𝐚, în Vlăhița, costul total fiind de 𝟔𝟎𝟎 𝐥𝐞𝐢.Nu rata ocazia de a experimenta o tabără de neuitat și înscrie-te achitând 𝐚𝐯𝐚𝐧𝐬𝐮𝐥 𝐝𝐞 𝟐𝟎𝟎 𝐥𝐞𝐢 luând legătura cu @corneliu.baltes sau @i_cris . </p><p>Dacă dorești și tu să cunoști mai mult viziunea lui Dumnezeu pentru viața ta, să cunoști oameni noi și să creăm amintiri împreună, hai și tu în tabără în perioada 𝟐𝟏-𝟐𝟔 𝐚𝐮𝐠𝐮𝐬𝐭!Can\'t waaaait!😍</p>',
            eventPoster: "/images/bg/2023/summer-camp.webp",
            eventPictures: []
        },
        {
            eventTitle: 'going-hiking,-vânătoarea-lui-buteanu',
            eventDate: '5 august 2023',
            eventTime: '9:00',
            eventLocation: 'Vârful VÂNĂTOAREA LUI BUTEANU (2507 metri)',
            eventDescription: '<p>Da, așa este, organizam o nouă drumeție și suntem entuziasmați să anunțăm startul înscrierilor!</p><p>Așa cum v-am obișnuit, locația este una dintre cele mai frumoase și de data aceasta vom ajunge pe vârful VÂNĂTOAREA LUI BUTEANU(2507 metri).</p><p>Traseul e de ~𝟑𝐡 și va începe de la Bâlea Lac, iar punctul de întâlnire a tuturor participanților va fi la Kaufland Șelimbăr, sâmbătă 𝟱 𝗮𝘂𝗴𝘂𝘀𝘁 la ora 𝟎𝟗:𝟎𝟎 𝐚.𝐦.</p><p>Trebuie doar să îți pregătești echipamentul minim necesar (bocanci, pelerina și altele) și haide să avem un timp calitativ și să ne bucurăm împreună de acest traseu!</p><p>Let’s goooo!🥳</p><p>Înscrierile s-au terminat. În cazul în care te gandesti sa vii cu noi, te rugam sa ne contactezi in privat.</p>',
            eventPoster: "/images/bg/2023/eveniment-hiking.webp",
            eventPictures: []
        },
        {
            eventTitle: 'board-games-night',
            eventDate: '14 iulie 2023',
            eventTime: '18:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>🎲 Îți este dor de Vox Dei, de oamenii din această comunitate și vrei să petreci o seară distractivă în compania prietenilor și să joci cele mai captivante jocuri de societate? Atunci vino la Board Games Night!</p><p>Indiferent dacă ești pasionat de jocurile strategice, jocurile de cuvinte sau preferi să îți pui abilitățile de comunicare la încercare, vei găsi cu siguranță jocuri pe gustul tău într-o atmosferă plină de bucurie și entuziasm.</p><p>De asemenea, te rugăm să te înscrii accesând 𝐥𝐢𝐧𝐤𝐮𝐥 𝐝𝐢𝐧 𝐁𝐈𝐎.</p><p>💡 P.S: Nu uita să inviți cu tine un prieten care nu frecventează bisericile evanghelice! Cu cât suntem mai mulți, cu atât mai distractiv va fi. Împărtășește acest anunț cu oricine ar putea fi interesat!</p><p>Vă așteptăm cu nerăbdare pe tine și pe prietenii tăi!</p>',
            eventPoster: "/images/bg/2023/board-games.webp",
            eventPictures: []
        },
        {
            eventTitle: 'gaming-night',
            eventDate: '23 iunie 2023',
            eventTime: '19:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>Hello, there! Te anunțăm că 𝐯𝐢𝐧𝐞𝐫𝐢, 𝟐𝟑 𝐢𝐮𝐧𝐢𝐞, începând cu ora 𝟏𝟗:𝟎𝟎, 𝐕𝐨𝐱 𝐃𝐞𝐢 organizează un 𝐥𝐚𝐧-𝐩𝐚𝐫𝐭𝐲 special. Așadar, vei avea oportunitatea de a te bucura de 𝐠𝐚𝐦𝐢𝐧𝐠 într-un mediu cu totul deosebit și prietenos. 🎉</p><p>Locurile sunt limitate, așa că asigură-ți participarea completând 𝐟𝐨𝐫𝐦𝐮𝐥𝐚𝐫𝐮𝐥 𝐝𝐢𝐧 𝐁𝐈𝐎. Fii pregătit să-ți arăți abilitățile și să-ți faci noi prieteni pasionați de gaming!</p><p>Deoarece punem accent pe partea de socializare cât mai diversificată, dorim să accentuăm ideea de a invita cu tine un prieten care nu frecventează bisericile evanghelice.</p><p>Te rugăm să aduci cu tine 𝐩𝐫𝐨𝐩𝐫𝐢𝐮𝐥 𝐝𝐢𝐬𝐩𝐨𝐳𝐢𝐭𝐢𝐯 (𝐏𝐂 𝐬𝐚𝐮 𝐥𝐚𝐩𝐭𝐨𝐩) pentru a te putea alătura turneelor și sesiunilor de joc. Nu uita să iei cu tine și căști pentru a te putea bucura de experiența completă a jocului. 🎧</p><p>Împărtășește acest anunț cu toți gamerii pe care îi cunoști și hai să petrecem împreună o seară epică! 🕹️</p>',
            eventPoster: "/images/bg/2023/gaming-night.webp",
            eventPictures: []
        },
        {
            eventTitle: 'let\'s-go-hiking,-tărnița',
            eventDate: '20 mai 2023',
            eventTime: '8:00',
            eventLocation: '𝐇𝐢𝐝𝐫𝐨𝐜𝐞𝐧𝐭𝐫𝐚𝐥𝐚 𝐓𝐚𝐫𝐧𝐢𝐭𝐚 până la 𝐏𝐢𝐚𝐭𝐫𝐚 𝐥𝐮𝐢 𝐋𝐮𝐜𝐚𝐜𝐢.',
            eventDescription: '<p>Da, așa este, organizam o nouă drumeție și suntem entuziasmați să anunțăm startul înscrierilor!</p><p>Așa cum v-am obișnuit, locația este una dintre cele mai frumoase și de data aceasta vom ajunge pe vârful VÂNĂTOAREA LUI BUTEANU(2507 metri).</p><p>Traseul e de ~𝟑𝐡 și va începe de la Bâlea Lac, iar punctul de întâlnire a tuturor participanților va fi la Kaufland Șelimbăr, sâmbătă 𝟱 𝗮𝘂𝗴𝘂𝘀𝘁 la ora 𝟎𝟗:𝟎𝟎 𝐚.𝐦.</p><p>Trebuie doar să îți pregătești echipamentul minim necesar (bocanci, pelerina și altele) și haide să avem un timp calitativ și să ne bucurăm împreună de acest traseu!</p><p>Let’s goooo!🥳</p><p>Înscrierile s-au terminat. În cazul în care te gandesti sa vii cu noi, te rugam sa ne contactezi in privat.</p>',
            eventPoster: "/images/bg/2023/hiking-tarnita.webp",
            eventPictures: [
                "/images/activitatiOutdoor/2023/10-hike-tarnita-1.webp",
                "/images/activitatiOutdoor/2023/10-hike-tarnita-2.webp",
                "/images/activitatiOutdoor/2023/10-hike-tarnita-3.webp",
                "/images/activitatiOutdoor/2023/10-hike-tarnita-4.webp",
                "/images/activitatiOutdoor/2023/10-hike-tarnita-5.webp",
                "/images/activitatiOutdoor/2023/10-hike-tarnita-6.webp",
                "/images/activitatiOutdoor/2023/10-hike-tarnita-7.webp",
                "/images/activitatiOutdoor/2023/10-hike-tarnita-8.webp",
                "/images/activitatiOutdoor/2023/10-hike-tarnita-9.webp"
              ]
        },
        {
            eventTitle: '1-mai',
            eventDate: '1 mai 2023',
            eventTime: '',
            eventLocation: 'Valea Moașei, Sibiu',
            eventDescription: '<p>𝐕𝐨𝐱𝐃𝐞𝐢 𝐰𝐢𝐥𝐥 𝐛𝐞 𝐨𝐮𝐭 𝐨𝐟 𝐭𝐨𝐰𝐧!🥳</p><p>Dacă și tu dorești să te recreezi, dar și să ai parte de un timp distractiv, de discuții și jocuri în echipă, avem o veste bună pentru tine!🥁</p><p>Organizăm o ieșire care va avea loc în data de 𝟏 𝐦𝐚𝐢, la Valea Moașei, iar costul acesteia va fi de 𝟑𝟎 𝐫𝐨𝐧, pe care îl poți achita la una dintre persoanele desemnate: @corneliu.baltes , @abeldurdun , @robert.onisie , @albertsecu .</p><p>Te-am convins?🤔 În cazul în care răspunsul este afirmativ, te invităm sa te înscrii completând formularul din bio, iar noi vom reveni pe parcurs cu mai multe detalii.</p><p>Hope to see you!✨</p>',
            eventPoster: "/images/bg/2023/unumai.webp",
            eventPictures: [
                "/images/activitatiOutdoor/2023/9-1-mai-1.webp",
                "/images/activitatiOutdoor/2023/9-1-mai-10.webp",
                "/images/activitatiOutdoor/2023/9-1-mai-2.webp",
                "/images/activitatiOutdoor/2023/9-1-mai-3.webp",
                "/images/activitatiOutdoor/2023/9-1-mai-4.webp",
                "/images/activitatiOutdoor/2023/9-1-mai-5.webp",
                "/images/activitatiOutdoor/2023/9-1-mai-6.webp",
                "/images/activitatiOutdoor/2023/9-1-mai-7.webp",
                "/images/activitatiOutdoor/2023/9-1-mai-8.webp",
                "/images/activitatiOutdoor/2023/9-1-mai-9.webp"
            ]
        },
        {
            eventTitle: 'worship-night',
            eventDate: '04 aprilie 2023',
            eventTime: '19:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>𝐋𝐞𝐭\'𝐬 𝐰𝐨𝐫𝐬𝐡𝐢𝐩 𝐆𝐨𝐝!🔥</p><p>Dorim tot mai mult să Îl lăudăm pe Dumnezeu și să ne închinăm Lui, dar nu oricum, ci împreună cu voi!</p><p>Te invităm să fii alături de noi marți, la următoarea întâlnire 𝐕𝐨𝐱𝐃𝐞𝐢, în cadrul căreia vom avea un timp special pentru a-L adora pe El.</p><p>Don\'t forget to bring a friend!🤗</p>',
            eventPoster: "/images/bg/2023/worship-night.webp",
            eventPictures: []
        },
        {
            eventTitle: 'lumos-meeting-2023',
            eventDate: '21 martie 2023',
            eventTime: '19:00',
            eventLocation: 'Lumos Coffee & Brunch, Sibiu',
            eventDescription: '<p>Într-un cadru mai restrâns, cu o atmosferă friendly, ne-am bucurat împreună de câteva momente valoroase. Acest timp a constat în idei și învățături utile pentru noi ca tineri înconjurați tot mai mult de mediul artificial, de asemenea ne-am putut bucura de socializare și de specialitățile din meniul celor de la 𝗟𝘂𝗺𝗼𝘀 𝗖𝗼𝗳𝗳𝗲𝗲 𝗮𝗻𝗱 𝗕𝗿𝘂𝗻𝗰𝗵.</p><p>Can\'t wait to see you again! 🤗</p>',
            eventPoster: "/images/bg/2023/lumos-2023.webp",
            eventPictures: [
                "/images/activitatiIndoor/2023/10-lumos-2023-1.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-10.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-11.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-2.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-3.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-4.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-5.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-6.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-7.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-8.webp",
                "/images/activitatiIndoor/2023/10-lumos-2023-9.webp"
              ]              
        },
        {
            eventTitle: 'campionat-de-volley',
            eventDate: '18 martie 2023',
            eventTime: '9:00',
            eventLocation: 'Gym (Sala de Sport), Șura mică',
            eventDescription: '<p>𝐖𝐢𝐧𝐧𝐞𝐫 𝐨𝐫 𝐥𝐨𝐬𝐞𝐫?🤔</p><p>Este cunoscut faptul că jocurile în echipă consolidează relațiile interumane, oferind posibilitatea de a cunoaște coechipierii, oportunitatea de a dezvolta anumite aptitudini, dar și petrecerea unui timp calitativ de recreere.</p><p>Din acest punct de vedere, putem afirma că în cadrul ultimului 𝐜𝐚𝐦𝐩𝐢𝐨𝐧𝐚𝐭 𝐝𝐞 𝐯𝐨𝐥𝐞𝐢 organizat de 𝐕𝐨𝐱𝐃𝐞𝐢, toți participanții au fost câștigători!🥳</p><p>Dorim să mai organizăm astfel de activități, so...𝐬𝐭𝐚𝐲 𝐭𝐮𝐧𝐞𝐝!🔥</p>',
            eventPoster: "/images/bg/2023/voxdei-volley.webp",
            eventPictures: [
                "/images/activitatiIndoor/2023/11-volei-2023-1.webp",
                "/images/activitatiIndoor/2023/11-volei-2023-10.webp",
                "/images/activitatiIndoor/2023/11-volei-2023-2.webp",
                "/images/activitatiIndoor/2023/11-volei-2023-3.webp",
                "/images/activitatiIndoor/2023/11-volei-2023-4.webp",
                "/images/activitatiIndoor/2023/11-volei-2023-5.webp",
                "/images/activitatiIndoor/2023/11-volei-2023-6.webp",
                "/images/activitatiIndoor/2023/11-volei-2023-7.webp",
                "/images/activitatiIndoor/2023/11-volei-2023-8.webp",
                "/images/activitatiIndoor/2023/11-volei-2023-9.webp"   
            ]
        },
        {
            eventTitle: 'winter-camp-2023',
            eventDate: '3-5 martie 2023',
            eventTime: '',
            eventLocation: 'Cabana Alpiniș, Păltiniș',
            eventDescription: '<p>Avem deja planuri pentru noul an!🔥</p><p>VoxDei organizează un weekend la munte, în perioada 03-05.03.2023 la Cabana Alpiniș.</p><p>🥁Așadar, anunțăm deschiderea înscrierilor! 🥁</p><p>Cum te poți înscrie? Achitând avansul nerambursabil de 200 lei, ce reprezintă jumătate din costul total, la una dintre persoanele desemnate: @corneliu.baltes , @abeldurdun sau @_alexple_06.</p><p>Va fi un weekend cu multe jocuri, distracție dar și cu un timp de worship, studiu și socializare!🏐🎶</p><p>Can\'t wait!🤩</p>',
            eventPoster: "/images/bg/2023/winter-camp-2023.webp",
            eventPictures: [
                "/images/tabere/2023/1-iarna-1.webp",
                "/images/tabere/2023/1-iarna-10.webp",
                "/images/tabere/2023/1-iarna-11.webp",
                "/images/tabere/2023/1-iarna-12.webp",
                "/images/tabere/2023/1-iarna-2.webp",
                "/images/tabere/2023/1-iarna-3.webp",
                "/images/tabere/2023/1-iarna-4.webp",
                "/images/tabere/2023/1-iarna-5.webp",
                "/images/tabere/2023/1-iarna-6.webp",
                "/images/tabere/2023/1-iarna-7.webp",
                "/images/tabere/2023/1-iarna-8.webp",
                "/images/tabere/2023/1-iarna-9.webp"
              ]   
        },
        {
            eventTitle: 'movie-night',
            eventDate: '07 martie 2023',
            eventTime: '19:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>> > > Știi ce urmează? > > ></p><p>Parcă niciodată nu există un moment inoportun pentru un film bun, cu atât mai mult când creezi atmosfera perfectă și ești înconjurat de oameni faini!</p><p>Așadar, te invităm la🎞️ M O V I E NIGHT la VoxDei!</p>',
            eventPoster: "/images/bg/2023/movie-night.webp",
            eventPictures: []   
        },
        {
            eventTitle: 'colindat-2022',
            eventDate: '24 decembrie 2022',
            eventTime: '19:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>"Astăzi, în cetatea lui David, vi s-a născut un Mântuitor, care este Hristos, Domnul.” Luca 2:11</p><p>Cum ne-am petrecut noi Crăciunul? Ducând vestea bună și sărbătorind nașterea Pruncului prin cântec. Ne-am bucurat de un număr foarte mare de colindători și de zâmbetele aduse gazdelor. Domnul sa fie laudat!</p>',
            eventPoster: "/images/bg/2022/colindat-2022.webp",
            eventPictures: [
                "/images/activitatiOutdoor/2022/30-colindat-1.webp",
                "/images/activitatiOutdoor/2022/30-colindat-2.webp",
                "/images/activitatiOutdoor/2022/30-colindat-3.webp",
                "/images/activitatiOutdoor/2022/30-colindat-4.webp",
                "/images/activitatiOutdoor/2022/30-colindat-5.webp",
                "/images/activitatiOutdoor/2022/30-colindat-6.webp",
                "/images/activitatiOutdoor/2022/30-colindat-7.webp"
              ]
        },
        {
            eventTitle: 'unbound-2022',
            eventDate: '02 - 03 decembrie 2022',
            eventTime: '19:00 și 10:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>Luna decembrie pentru noi a avut un început minunat!</p><p>Credem că UNBOUND a fost un prim pas încurajator pentru proiectul nostru din cadrul comunității VoxDei. 🫶</p><p>Suntem recunoscători pentru fiecare persoană ce a participat, pentru momentele de socializare, pentru sesiunile de laudă și închinare aduse înaintea Domnului, pentru mesajul concentrat și atât de necesar dar mai mult decât orice, predomină mulțumirea că El a fost prezent printre noi.😇</p><p>UNBOUND s-a încheiat dar sunt lucruri importante ce au rămas în urma acestei conferințe și continuă să influențeze într-un mod pozitiv viețile noastre. Iar acestea sunt prieteniile create sau consolidate în această perioadă, amintirile prețioase, modul în care am fost cercetați dar și cunoștințele, ideile ce le-am asimilat din mesajul susținut de pastorul Lucian Bălănescu. 🔥</p><p>Te încurajăm să urmărești pagina noastră de Instagram sau Facebook pentru a afla despre evenimentele viitoare ce le vom mai organiza!</p><p>Stay tuned! 🔜</p>',
            eventPoster: "/images/bg/2022/unbound-2022.webp",
            eventPictures: [
                "/images/unbound/2022/17.webp",
                "/images/unbound/2022/18.webp",
                "/images/unbound/2022/19.webp",
                "/images/unbound/2022/09.webp",
                "/images/unbound/2022/10.webp",
                "/images/unbound/2022/11.webp",
                "/images/unbound/2022/12.webp",
                "/images/unbound/2022/13.webp",
                "/images/unbound/2022/14.webp",
                "/images/unbound/2022/15.webp",
                "/images/unbound/2022/16.webp",
            ]
        },
        {
            eventTitle: 'seară-socializare-2022',
            eventDate: '15 noiembrie 2022',
            eventTime: '19:00',
            eventLocation: 'Biserica Betania, Sibiu',
            eventDescription: '<p>Sunteți pregătiți pentru o seară de socializare VoxDei?</p><p>Voi aduceți un good vibe iar noi ne ocupăm de Coffee & Tea.</p><p>Organizăm această seară pentru că ne dorim să vă cunoaștem mai bine, să puteți afla mai multe despre cine suntem noi și care sunt obiectivele principale ale acestei comunități.</p><p>Vrem să avem un timp frumos în care să povestim, să ne bucurăm împreună și să ne împrietenim.</p>',
            eventPoster: "/images/bg/2022/seara-socializare-2022.webp",
            eventPictures: []
        },
        {
            eventTitle: 'going-hiking,-rozdesti',
            eventDate: '22 octombrie 2022',
            eventTime: '9:45',
            eventLocation: 'Paltinis-Saua, Batrana-vf Rozdesti.',
            eventDescription: '<p>VoxDei se pregătește de o nouă drumeție!</p><p>Pentru că feedback-ul după fiecare drumeție a fost unul pozitiv, amintirile au fost printre cele mai frumoase, ne-am decis sa organizăm o nouă drumeție, de data aceasta traseul fiind: Paltinis-Saua Batrana- vf Rozdesti.</p><p>Plecarea va fi sâmbătă 22. 10. 2022, la ora 9:45 din parcarea bazinului Olimpia( langa parcul Subarini).</p><p>Dacă ai chef de urcat, ai mâncare, apă în rucsac și poți face rost de încălțămintea și îmbrăcămintea potrivită ( eventual de o pelerină), hai cu noi!🤗</p><p>Înscrierile pentru drumeție s-au încheiat, dar dacă totuși ai vrea să vii cu noi mâine, lasă-ne un mesaj în privat.</p>',
            eventPoster: "/images/bg/2022/going-hiking.webp",
            eventPictures: [
                "/images/activitatiOutdoor/2022/29-rozdesti-1.webp",
                "/images/activitatiOutdoor/2022/29-rozdesti-2.webp",
                "/images/activitatiOutdoor/2022/29-rozdesti-3.webp",
                "/images/activitatiOutdoor/2022/29-rozdesti-4.webp",
                "/images/activitatiOutdoor/2022/29-rozdesti-5.webp",
                "/images/activitatiOutdoor/2022/29-rozdesti-6.webp"
              ]
        },
        {
            eventTitle: 'going-hiking,-mălăiești',
            eventDate: '18 iunie 2022',
            eventTime: '7:30 AM',
            eventLocation: 'Mălăiești, Râșnov, Brașov',
            eventDescription: '<p>Mergem până la cabana Mălăiești, Râșnov, cu VoxDei!</p><p>Pe 13 iunie, ne întâlnim in zona Kaufland, Șelimbăr la ora 7:30 AM să plecăm în drumeție! (traseu de ~6-7h +2 h cu mașina până în zona destinației)</p><p>Dacă ai chef de urcat, ai mâncare și apă în rucsac și poți face rost de încălțămintea potrivită și eventual și de o pelerină, hai cu noi!🤗</p><p>Vom avea parte de un timp tare fain.</p><p>Înscrierile pentru drumeție s-au încheiat, dar dacă totuși ai vrea să vii cu noi mâine, lasă-ne un mesaj în privat. 🌲</p>',
            eventPoster: "/images/bg/2022/going-hiking.webp",
            eventPictures: []
        },
    ]

    const nextImage = () => {
        setCurrentImageIndex((currentImageIndex + 1) % selectedEvent.eventPictures.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((currentImageIndex - 1 + selectedEvent.eventPictures.length) % selectedEvent.eventPictures.length);
    };

    useEffect(() => {
        if (selectedEvent && selectedEvent.eventPictures.length > 0) {
            const interval = setInterval(() => {
                setCurrentImageIndex(prevIndex =>
                    (prevIndex + 1) % selectedEvent.eventPictures.length
                );
            }, 5000); // Change image every 5 seconds

            return () => clearInterval(interval); // Cleanup the interval on unmount
        }
    }, [selectedEvent]);

    useEffect(() => {
        // Find the event that matches the received title
        const event = eventsArray.find(event => event.eventTitle === eventTitle);

        if (!event) {
            // Navigate to the 404 page
            navigate('/404', { replace: true });
        } else {
            setSelectedEvent(event);
        }
    }, [formattedEventTitle, navigate]);

    if (!selectedEvent) {
        // Return a loading state or something while the event data is being fetched
        return null;
    }

    return (
        <section className="projects packery">
            {/* <section className="project-details bg-dark-200"> */}
            <SectionGridLines></SectionGridLines>
            <div className="large_font">
                <SectionHeader title="Evenimente"></SectionHeader>
            </div>
            <div className="container">
                <div className="section-header text-center has_line">
                    <PageHeader className="text-white" title={formattedEventTitle}></PageHeader>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <img src={selectedEvent.eventPoster} alt={selectedEvent.eventTitle} className="event-poster" />
                        <EventSidebar event={selectedEvent} />
                    </div>

                    <div className="col-lg-8 col-md-8">
                        <EventDetailsInner description={selectedEvent.eventDescription} />
                        {/* Automatic scrolling gallery */}
                        {selectedEvent.eventPictures.length > 0 && (
                            <div className="image-gallery">
                                <img
                                    src={selectedEvent.eventPictures[currentImageIndex]}
                                    alt={`Event ${currentImageIndex}`}
                                    className="gallery-image"
                                    onClick={() => handleFullscreenImageOpen(selectedEvent.eventPictures[currentImageIndex])}
                                />
                                <div className="gallery-controls">
                                    <button className="gallery-control prev" onClick={prevImage}>
                                        &lt;
                                    </button>
                                    <div className="dots">
                                        {selectedEvent.eventPictures.map((_, index) => (
                                            <div
                                                key={index}
                                                className={`gallery-dot ${currentImageIndex === index ? 'active' : ''}`}
                                                onClick={() => setCurrentImageIndex(index)}
                                            ></div>
                                        ))}
                                    </div>
                                    <button className="gallery-control next" onClick={nextImage}>
                                        &gt;
                                    </button>
                                </div>
                            </div>
                        )}
                        {fullscreenImage && (
                            <FullscreenImage
                                src={fullscreenImage}
                                onClose={handleFullscreenImageClose}
                            />
                        )}
                    </div>
                </div>

            </div>
        </section>
    );
};

export default EventDetailsContainer;