import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import UnboundSliderCard from '../../components/SliderElements/UnboundSliderCard';

const UnboundSlider = () => {
    var bgImage = ""

    if (window.innerWidth > 500)
        bgImage = "/images/bg/2024/unbound-page-2024.webp"
    else
        bgImage = "/images/bg/2024/unbound-page-mobile-2024.webp"
    return (
        <div className="theme_slider_3 p-0">
            <div className="swiper swiper_theme_slider_3">
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    loop={true}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                >
                    <SwiperSlide>
                        {/* <UnboundSliderCard bgImg="images/slider/15.jpg" title="The Future Architecture Is Here" /> */}
                        <UnboundSliderCard bgImg={bgImage} title="Unbound" />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        
                        <UnboundSliderCard bgImg="images/slider/3.jpg" title="We value your Dream" />
                    </SwiperSlide>
                    <SwiperSlide>
                       
                        <UnboundSliderCard bgImg="images/slider/5.jpg" title="Make true your Dream with Mrittik" />
                    </SwiperSlide> */}
                </Swiper>

                {/* <!-- Add Buttons --> */}
                {/* <div className="swiper-button-prev details_link l-dir pagination-previous"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                <div className="swiper-button-next details_link r-dir pagination-next"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div> */}
            </div>
            {/* 
            <div className="rotate-logo d-none d-md-block">
                Vox Dei
            </div> */}
        </div>
    );
};

export default UnboundSlider;