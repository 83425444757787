import React, { useRef, useEffect } from "react";

const GuideSlotModal = ({ isOpen, onClose, title, speaker, description, imageSrc }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  // const handleModalClick = (event) => {
  //   // Close the modal if the overlay is clicked
  //   if (event.target === modalRef.current) {
  //     onClose();
  //   }
  // };

  if (!isOpen) return null;

  // Replace newline characters with <br />
  const formattedDescription = description.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <div className="guide-slot-modal-overlay">
      {/* onClick={handleModalClick}> */}
      <div className="guide-slot-modal-content" ref={modalRef}>
        <span className="guide-slot-modal-close" onClick={onClose}>&times;</span>
        <h2 className="guide-slot-modal-title">{title}</h2>
        <div className="guide-slot-modal-info">
          <figure className="guide-slot-modal-figure">
            <picture>
              <img src={imageSrc} alt="" />
            </picture>
            <figcaption className="guide-slot-modal-figcaption">{speaker}</figcaption>
          </figure>
          <div className="guide-slot-modal-description">
            <p>{formattedDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideSlotModal;
