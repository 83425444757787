import React from 'react';

const EventSidebar = ({ event }) => {
    return (
        <div className="sidebar">
            <div className="project-information">
                <h6 className="widget-title">
                    Detalii
                    <span className="title-line"></span>
                </h6>
                <ul>
                    {event.eventDate && (
                        <li>
                            <span className="data">Data:</span> <span className="value">{event.eventDate}</span>
                        </li>
                    )}
                    {event.eventTime && (
                        <li>
                            <span className="data">Ora:</span> <span className="value">{event.eventTime}</span>
                        </li>
                    )}
                    {event.eventLocation && (
                        <li>
                            <span className="data">Locația:</span> <span className="value">{event.eventLocation}</span>
                        </li>
                    )}
                </ul>

                {/* <div className="project-share">
                    <ul>
                        <li>Share:</li>
                        <li><a href="#"><i className="bi bi-facebook"></i></a></li>
                        <li><a href="#"><i className="bi bi-twitter"></i></a></li>
                        <li><a href="#"><i className="bi bi-instagram"></i></a></li>
                        <li><a href="#"><i className="bi bi-youtube"></i></a></li>
                        <li><a href="#"><i className="bi bi-pinterest"></i></a></li>
                    </ul>
                </div> */}
            </div>
        </div>
    );
};

export default EventSidebar;
