import React from "react";
import SubHeading from "../../components/SubHeading";

const AboutContainer = () => {
  return (
    <section className="about bg-dark-100">
      <div className="large_font">
        <h2 className="floating_element text-dark-200 d-flex justify-content-center">
          Despre
        </h2>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-5">
            <div className="about_image">
              <img src="images/bg/about-us-image.webp" alt="img" />
              <img src="images/about/e1.svg" alt="About" />
            </div>
          </div>
          <div className="col-lg-6 col-md-7">
            <div className="about_text_inner">
              <div className="about_text">
                <SubHeading title="Prin dragostea lui Dumnezeu, iubim pe ceilalți"></SubHeading>
                <p>Vox Dei s-a născut într-un context post-modern și continuă să se dezvolte într-unul post-adevăr. Suntem convinși de faptul că Scripturile sunt relevante și astăzi, iar Cuvântul lui Dumnezeu poate vorbi impactând generația tânără.</p>
                <p>Ne dorim să fim mai apropiați de Dumnezeu formând caractere biblice și aducând împreună tinerii la închinare.</p>
                <p>Nu ținem credința doar pentru noi. Vrem să împărtășim bucuria Evanghelei prin discuții și întâlniri colective.</p>
                <p>Vox Dei este o comunitate primitoare, și dorim să fie un alt <i>"acasă"</i>. Ne susținem reciproc în căutarea noastră spirituală și ne bucurăm împreună de parcursul vieții de credință a fiecăruia.</p>
                <p>Așadar, Vox Dei te cheamă să crești spiritual, împreună cu ceilalți. Alătură-te comunității noastre, manifestă practic credința ta și transformă contextul în care trăiești.</p>
              </div>

              {/* <div className="about_icon_box">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i2.svg" text="3D Space Designing"></IconBoxFlex>
                                    </div>
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i3.svg" text="Architectural Drawing"></IconBoxFlex>
                                    </div>
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i1.svg" text="3D Model building"></IconBoxFlex>
                                    </div>
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i4.svg" text="Building The Space"></IconBoxFlex>
                                    </div>
                                </div>
                            </div> */}

              {/* <div className="btn_group">
                <a href="/about" className="btn gray">
                  Mai mult
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutContainer;
