import React from 'react';
import GalleryMedia from '../../containers/ProjectContainers/GalleryMedia';

const MediaPage = () => {
    document.title = "Vox Dei - Media";
    
    return (
        <>
            <GalleryMedia />
        </>
    );
};


export default MediaPage;