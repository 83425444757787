import React, { useState, useEffect, createRef } from 'react';
import axios from 'axios';
import PageHeader from "../components/PageHeader";
import SectionHeader from "../components/SectionHeader";
import InstagramModal from './InstagramModal';

const InstagramFeed = () => {
  const [media, setMedia] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getInstagramMedia = async () => {
      try {
        const response = await axios.get('/api/instagram/media');
        if (Array.isArray(response.data)) {
          const mediaWithRefs = response.data.map(item => ({
            ...item,
            videoRef: createRef(),
          }));
          setMedia(mediaWithRefs);
        } else {
          console.error('Invalid response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching Instagram media:', error);
      }
    };

    getInstagramMedia();
  }, []);

  const handleMouseEnterItem = (videoRef, itemId) => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error('Video play error:', error);
      });
    }

    adjustCaptionFontSize(itemId);
  };

  const handleMouseLeaveItem = (videoRef, isClicking) => {
    if (!isClicking && videoRef.current) {
      const timer = setTimeout(() => {
        videoRef.current.pause();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  };

  useEffect(() => {
    return () => {
      media.forEach(item => {
        if (item.videoRef.current) {
          item.videoRef.current.pause();
          item.videoRef.current.currentTime = 0;
        }
      });
    };
  }, [media]);

  const handleItemClick = item => {
    let currentTime = 0;
    if (item.media_type === 'VIDEO' && item.videoRef.current) {
      item.videoRef.current.pause();
      currentTime = item.videoRef.current.currentTime;
    }

    setSelectedItem({ ...item, currentTime });
    setIsModalOpen(true);
  };

  const adjustCaptionFontSize = (itemId) => {
    const item = document.getElementById(`instagram-item-${itemId}`);
    const caption = item.querySelector('.caption');

    if (caption) {
      const itemWidth = item.offsetWidth;
      const itemHeight = item.offsetHeight;

      const fontMultiplyer = window.innerWidth > 500 ? 10 : 5;
      const fontSize = Math.min(itemWidth / caption.scrollWidth, itemHeight / caption.scrollHeight) * fontMultiplyer;
      caption.style.fontSize = `${fontSize}px`;
    }
  };

  useEffect(() => {
    const adjustCaptionFontSize = () => {
      const items = document.querySelectorAll('.instagram-item');
      items.forEach(item => {
        const caption = item.querySelector('.caption');
        if (caption) {
          const scaleFactor = (item.offsetWidth / caption.scrollWidth);
          const baseFontSize = window.innerWidth > 500 ? 0.8 : 0.0;
          caption.style.fontSize = `${baseFontSize * scaleFactor}em`;
        }
      });
    };

    adjustCaptionFontSize();

    window.addEventListener('resize', adjustCaptionFontSize);

    return () => {
      window.removeEventListener('resize', adjustCaptionFontSize);
    };
  }, []);

  return (
    <section className="projects masonry bg-transparent effect-tilt">
      <div className="instagram-feed-container">
        <div className="large_font">
          <SectionHeader title="Instagram"></SectionHeader>
        </div>
        <div className="section-header text-center has_line">
          <PageHeader className="text-white" title="Instagram Feed"></PageHeader>
        </div>
        <div className="instagram-feed">
          {media.map(item => (
            <div
              key={item.id}
              className={`instagram-item ${item.media_type}`}
              onMouseEnter={() => handleMouseEnterItem(item.videoRef, item.id)}
              onMouseLeave={() => handleMouseLeaveItem(item.videoRef, selectedItem === item)}
              onClick={() => handleItemClick(item)}
              id={`instagram-item-${item.id}`}
            >
              {item.media_type !== 'VIDEO' && (
                <img src={item.media_url} alt={item.caption} className="media image" />
              )}
              {item.media_type === 'VIDEO' && (
                <div className="video-wrapper">
                  <video
                    ref={item.videoRef}
                    src={item.media_url}
                    poster={item.thumbnail_url}
                    className="media video"
                    muted
                    loop
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
              <p className="caption">{item.caption}</p>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <InstagramModal
          item={selectedItem}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedItem(null);
          }}
        />
      )}
    </section>
  );
};

export default InstagramFeed;
