import React from 'react';

const HealthCheck = () => {
    return (
        <section className="highlight_banner bg-dark-200">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-11 p-lg-0">
                        <p className="about_para text-center">Ok</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HealthCheck;