import React from 'react';
import '../assets/css/style.css';

const ErrorPage = () => {
    document.title = "Vox Dei - Error";

    return (
        <>
            <div className="header-layer-bg"></div>

            <main className="wrapper">
                <section className="error-page">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-10 offset-lg-1">
                                <div className="error-content text-center">
                                    <div className="heading">404</div>
                                    <h1>Pagina nu a fost găsită</h1>
                                    <p>Oops! Pagina pe care o cauți nu există sau a fost ștearsă.</p>

                                    <div className="btn_group">
                                        <a href='/' className="btn black">Întoarce-te la pagina principală</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default ErrorPage;