import $ from "jquery";
import React, { useEffect } from "react";
import SectionGridLines from "../../../components/SectionGridLines";

const Footer = () => {
  //

  useEffect(() => {
    // Totop Button
    $(".totop a").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    // Hide header on scroll down
    const nav = document.querySelector(".header");
    const scrollUp = "top-up";
    let lastScroll = 800;

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 800) {
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
        return;
      }

      if (currentScroll > lastScroll) {
        // down
        nav.classList.add(scrollUp);
        $(".totop").addClass("show");
      } else if (currentScroll < lastScroll) {
        // up
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
      }
      lastScroll = currentScroll;
    });
  }, []);
  return (
    <>
      <footer className="footer bg-dark-200 box_padding">
        <div className="footer_inner bg-black">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-4 col-md-2 col-sm-2">
                <div className="section-header">
                  <h2>Contact</h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="communication">
                  <div className="info_body">
                    <h6 className="footer-style">Parte a</h6>
                    <h5 className="footer-style">
                      <a href="https://www.betaniasibiu.ro" target='_blank' rel="noreferrer">
                        Bisericii Betania Sibiu
                      </a>
                    </h5>
                  </div>
                  <div className="info_body">
                    <h6 className="footer-style">Email</h6>
                    <h5 className="footer-style"><a href = "mailto: contact@voxdei.ro">
                      contact@voxdei.ro</a></h5>
                  </div>
                 
                  <div className="info_body">
                    <h6 className="footer-style">Adresa</h6>
                    <h5 className="footer-style"><a href='https://www.google.com/maps?q=Biserica%20Baptista%20Betania%20Sibiu' target='_blank' rel="noreferrer">
                      Str. Pielarilor, Nr. 6-8, Sibiu, Romania</a></h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="footer_elements d-flex align-items-center justify-content-end">
                  <div className="footer_elements_inner">
                    <div className="footer_logo">
                      <a href="/" className="light_logo ">
                        <img
                          width="140px"
                          height="40px"
                          src="/images/main-logo-cropped.webp"
                          alt="logo"
                        />
                      </a>
                    </div>
                    <div className="footer_social">
                      <ul className="social_list">
                      <li className="instagram">
                          <a href="https://www.instagram.com/voxdeisibiu/" target='_blank' rel="noreferrer" aria-label="View our Instagram Profile">
                            <i className="bi bi-instagram"></i>
                          </a>
                        </li>
                        <li className="facebook">
                          <a href="https://www.facebook.com/Voxdeisibiu/" target='_blank' rel="noreferrer" aria-label="View our Facebook Profile">
                            <i className="bi bi-facebook"></i>
                          </a>
                        </li>      
                      </ul>
                    </div>
                    {/* <div className="terms_condition">
                                            <ul>
                                                <li><a>Terms</a></li>
                                                <li><a>Condition</a></li>
                                                <li><a>Policy</a></li>
                                            </ul>
                                        </div> */}
                    <div className="copyright">
                      <p>Beru Agency 2023. All Rights Reserved</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SectionGridLines />
        </div>
      </footer>

      <div className="totop">
        <a href="!#">UP</a>
      </div>
    </>
  );
};

export default Footer;
