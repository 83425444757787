import React from 'react';
import { Link } from 'react-router-dom';

const EventCard = (props) => {
    // Encode the title to make it URL-friendly
    const encodedTitle = encodeURIComponent(props.title.replace(/ /g, '-')).toLowerCase();

    return (
        <div className="icon_box type-2">
            <img width="410px" height="350px" src={props.img01} alt="img" className="post-bg" />
            {/* Use the Link component with the encoded title in the to prop */}
            <Link to={`/event-details/${encodedTitle}`}>
                <div className="icon_box_inner">
                    <img width='100px' height="30px" src={props.img02} alt="Icon Box" />
                    <h4 className="text-white">{props.title}</h4>
                    <p className="text-gray-600">{props.text}</p>
                    <div className="arrow_effect">
                    <a href='!#'><span className="crossline1"></span><span className="crossline2"></span></a>

                    </div>
                </div>
            </Link>
        </div>
    );
};

export default EventCard;
