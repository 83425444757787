// Import necessary libraries
import React from 'react';
import YouTube from 'react-youtube';

// Define your React component
class YouTubeVideo extends React.Component {
  render() {
    // YouTube video options (you can customize these options)
    const opts = {
      height: '390',
      width: '85%', // Set width to 100% to make it responsive
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0, // Set to 1 if you want autoplay
      },
    };

    // YouTube video ID (replace with your own video ID)
    const videoId = '7hp2i5CChsI';

    return (
      <div style={{ maxWidth: '85%', margin: 'auto' }}>
        <YouTube
          videoId={videoId}
          opts={opts}
          onReady={(event) => {
            // access to player in all event handlers via event.target
            event.target.pauseVideo(); // You can perform additional actions here
          }}
        />
      </div>
    );
  }
}

export default YouTubeVideo;


// import React, { useState } from 'react';
// import YouTube from 'react-youtube';

// class YouTubeVideo extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       isPlaying: false,
//     };
//   }

//   onPlay = () => {
//     this.setState({ isPlaying: true });
//   };

//   onPause = () => {
//     this.setState({ isPlaying: false });
//   };

//   render() {
//     const { isPlaying } = this.state;

//     // YouTube video options (you can customize these options)
//     const opts = {
//       height: '390',
//       width: '85%', // Set width to 100% to make it responsive
//       playerVars: {
//         // https://developers.google.com/youtube/player_parameters
//         autoplay: 0, // Set to 1 if you want autoplay
//       },
//     };

//     // YouTube video ID (replace with your own video ID)
//     const videoId = 'Xe0pN_56-3o';

//     return (
//       <div style={{ maxWidth: '85%', margin: 'auto' }}>
//         {isPlaying ? (
//           <YouTube
//             videoId={videoId}
//             opts={opts}
//             onReady={(event) => {
//               // access to player in all event handlers via event.target
//               event.target.pauseVideo(); // You can perform additional actions here
//             }}
//             onPlay={this.onPlay}
//             onPause={this.onPause}
//           />
//         ) : (
//           <img
//             src="/images/bg/2023/maxresdefault.webp" // Replace with your custom thumbnail URL
//             alt="Motivul Crăciunului - Vox Dei"
//             style={{ cursor: 'pointer', width: '100%' }}
//             onClick={() => this.setState({ isPlaying: true })}
//           />
//         )}
//       </div>
//     );
//   }
// }

// export default YouTubeVideo;
