import React, { useRef, useState, useEffect } from "react";
import FullscreenImage from "../../components/FullscreenImage"; // Path to your FullscreenImage component
import GuideSlotModal from "../../components/GuideSlotModal";

// const AutomaticPlayer = function ({ videoRef }) {
//   const [playing, setPlaying] = useState(false);

//   useEffect(() => {
//     const handleIntersection = (entries) => {
//       const isVisible = entries[0].isIntersecting;
//       if (!isVisible) {
//         videoRef.current.pause();
//         setPlaying(false);
//       } else {
//         // Video is back in view, restart if not already playing
//         if (!playing) {
//           videoRef.current.play();
//           setPlaying(true);
//         }
//       }
//     };

//     const observer = new IntersectionObserver(handleIntersection, {
//       root: null,
//       rootMargin: "0px",
//       threshold: 0.5, // Adjust this threshold as needed
//     });

//     if (videoRef.current) {
//       observer.observe(videoRef.current);
//     }

//     return () => {
//       if (videoRef.current) {
//         observer.unobserve(videoRef.current);
//       }
//     };
//   }, [playing, videoRef]);

//   return (
//     <video
//       ref={videoRef}
//       poster="/images/bg/unbound-thumbnail.webp"
//       className="video-bottom"
//       width="320"
//       height="240"
//       loop
//       muted
//       controls
//       autoPlay
//       playsInline
//       webkit-playsinline="true"
//       src='images/teaserunbound.mp4'
//     >
//       Your browser does not support the video tag.
//     </video>
//   )
// };

const UnboundContainer = () => {
  const trackRef = useRef(null);
  // const videoRef = useRef(null); // Define a separate videoRef for UnboundContainer
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSpeaker, setModalSpeaker] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalImageSrc, setModalImageSrc] = useState("");

  const openModal = (title, speaker, description, imageSrc) => {
    setModalTitle(title);
    setModalSpeaker(speaker)
    setModalDescription(description);
    setModalImageSrc(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFullscreenImageOpen = (src) => {
    setFullscreenImage(src);
  };

  const handleFullscreenImageClose = () => {
    setFullscreenImage(null);
  };

  const handleOnDown = (e) => {
    const isTouchEvent = e.touches && e.touches.length > 0;
    trackRef.current.dataset.mouseDownAt = isTouchEvent ? e.touches[0].clientX : e.clientX;
    trackRef.current.dataset.mouseClicked = "false";
    trackRef.current.dataset.isDragging = "false";
  };

  const handleOnUp = () => {
    const track = trackRef.current;
    if (track.dataset.isDragging === "false") {
      // Open the fullscreen image if not part of a drag action
      const clickedImage = Array.from(track.getElementsByClassName("image")).find(
        (image) => image.dataset.mouseClicked === "true"
      );
      if (clickedImage) {
        handleFullscreenImageOpen(clickedImage.src);
        clickedImage.dataset.mouseClicked = "false";
      }
    }

    track.dataset.mouseDownAt = "0";
    track.dataset.prevPercentage = track.dataset.percentage;
    track.dataset.isDragging = "false";
  };

  const handleOnMove = (e) => {
    const track = trackRef.current;

    if (track.dataset.mouseDownAt === "0") return;

    const isTouchEvent = e.touches && e.touches.length > 0;
    const eventX = isTouchEvent ? e.touches[0].clientX : e.clientX;
    const mouseDelta = parseFloat(track.dataset.mouseDownAt) - eventX;
    const maxDelta = window.innerWidth / 2;

    const percentage = (mouseDelta / maxDelta) * -100;
    const nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage;

    const mobileScrollRange = window.innerWidth <= 500 ? 220 : 0;

    const nextPercentage = Math.max(
      Math.min(nextPercentageUnconstrained, 0),
      -200 - mobileScrollRange
    );

    track.dataset.percentage = nextPercentage;

    if (Math.abs(mouseDelta) >= 1) {
      track.dataset.isDragging = "true";
    }

    track.style.transform = `translateX(${nextPercentage}%)`;

    for (const image of track.getElementsByClassName("image")) {
      const objectPositionValue = Math.min(100, Math.max(0, 100 + nextPercentage));
      image.style.objectPosition = `${objectPositionValue}% center`;
    }
  };

  const handleScroll = (e) => {
    if (!trackRef.current.contains(e.target)) {
      return; // Not inside the image track
    }

    e.preventDefault(); // Prevent default vertical scrolling

    const track = trackRef.current;

    // Calculate the wheel delta based on the vertical scroll
    const wheelDeltaY = e.deltaY || e.nativeEvent.deltaY;
    const maxDeltaY = track.clientHeight - window.innerHeight;

    const prevPercentage = parseFloat(track.dataset.prevPercentage) || 0;
    const wheelDeltaPercentage = (wheelDeltaY / maxDeltaY) * -100;
    const nextPercentageUnconstrained = prevPercentage + wheelDeltaPercentage;

    const mobileScrollRange = window.innerWidth <= 500 ? 220 : 0;

    const nextPercentage = Math.max(
      Math.min(nextPercentageUnconstrained, 0),
      -200 - mobileScrollRange
    );

    track.dataset.prevPercentage = nextPercentage;
    track.style.transform = `translate(${nextPercentage}%, 0%)`;

    for (const image of track.getElementsByClassName("image")) {
      const objectPositionValue = Math.min(
        100,
        Math.max(0, 100 + nextPercentage)
      );
      image.style.objectPosition = `${objectPositionValue}% center`;
    }
  };

  useEffect(() => {
    const handleWindowWheel = (e) => {
      if (trackRef.current.contains(e.target)) {
        e.preventDefault(); // Prevent default vertical scrolling
      }
    };

    // Use passive: false to ensure preventDefault works
    window.addEventListener("wheel", handleWindowWheel, { passive: false });

    // Handle touchmove event similarly for iOS
    const handleTouchMove = (e) => {
      if (trackRef.current.contains(e.target)) {
        e.preventDefault();
      }
    };
    window.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWindowWheel);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  const images = [
    "/images/unbound/2023/06.webp",
    "/images/unbound/2023/05.webp",
    "/images/unbound/2023/04.webp",
    "/images/unbound/2023/09.webp",
    "/images/unbound/2023/10.webp",
    "/images/unbound/2023/07.webp",
    "/images/unbound/2023/12.webp",
    "/images/unbound/2023/13.webp",
    "/images/unbound/2023/14.webp",
    "/images/unbound/2023/15.webp",
    "/images/unbound/2023/16.webp",
  ];

  const scheduleContainer = document.querySelector('.schedule-container');

  if (scheduleContainer) {
    let isDown = false;
    let startX;
    let scrollLeft;
    scheduleContainer.style.cursor = 'grab';

    scheduleContainer.addEventListener('mousedown', (e) => {
      isDown = true;
      startX = e.pageX - scheduleContainer.offsetLeft;
      scrollLeft = scheduleContainer.scrollLeft;
      scheduleContainer.style.cursor = 'grabbing'; // Change cursor to grabbing
    });

    scheduleContainer.addEventListener('mouseleave', () => {
      isDown = false;
      scheduleContainer.style.cursor = 'grab'; // Reset cursor
    });

    scheduleContainer.addEventListener('mouseup', () => {
      isDown = false;
      scheduleContainer.style.cursor = 'grab'; // Reset cursor
    });

    scheduleContainer.addEventListener('mousemove', (e) => {
      if (!isDown) return; // Stop the function if not dragging
      e.preventDefault();
      const x = e.pageX - scheduleContainer.offsetLeft;
      const walk = (x - startX) * 2; // Adjust scrolling speed
      scheduleContainer.scrollLeft = scrollLeft - walk;
    });
  }
  return (
    <section className="projects bg-transparent effect-tilt p-0">
      <div className="container">
        <section className="section-container unbound">
          {/* <h1 className="heading">Teaser</h1>
          <AutomaticPlayer videoRef={videoRef} /> */}
          <h1 className="heading">2023</h1>
        </section>

        {fullscreenImage && (
          <FullscreenImage
            src={fullscreenImage}
            onClose={(e) => {
              e.stopPropagation(); // Prevent event propagation
              handleFullscreenImageClose();
            }}
          />
        )}

        <div
          ref={trackRef}
          onMouseDown={handleOnDown}
          onTouchStart={handleOnDown}
          onClick={(e) => {
            if (e.target.classList.contains("image")) {
              e.stopPropagation(); // Prevent event propagation
              handleFullscreenImageOpen(e.target.src);
            }
          }}
          onMouseUp={handleOnUp}
          onTouchEnd={handleOnUp}
          onMouseMove={handleOnMove}
          onTouchMove={handleOnMove}
          onWheel={handleScroll}
          id="image-track"
          data-mouse-down-at="0"
          data-prev-percentage="0"
        >
          {images.map((src, index) => (
            <img
              key={index}
              className="image"
              src={src}
              draggable="false"
              alt={`${index}`}
              data-mouse-clicked="false"
            />
          ))}
        </div>

        <section className="under-construction-page">
          <h1 className="heading">Program</h1>
          {/* <div className="container">
            <div className="row">
              <div className="col col-lg-10 offset-lg-1">
                <div className="under-construction-content text-center">
                  <div className="heading">Coming Soon</div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
        <hr className="divider" />
        <GuideSlotModal
          isOpen={modalOpen}
          onClose={closeModal}
          title={modalTitle}
          speaker={modalSpeaker}
          description={modalDescription}
          imageSrc={modalImageSrc}
        />
        <div className="schedule-container unbound">
          <div className="schedule-stage__wrapper">
            <section className="schedule-stage">
              <div className="schedule-stage__title">
                <h3>Vineri</h3>
              </div>
              <div className="schedule-stage_guide-container">
                <div className="guide-slot">
                  <p>17:00 - 18:00</p>
                  <div className="guide-slot_card">
                    <h4>Check-in & Coffee </h4>
                  </div>
                </div>
                <div className="guide-slot">
                  <p>18:00 - 18:40</p>
                  <div className="guide-slot_card">
                    <h4>Laudă și închinare</h4>
                  </div>
                </div>
                <div className="guide-slot">
                  <p>18:40 - 19:30</p>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Sesiune plenară", "Ciprian Terinte", "Pastor în Biserica Penticostală \„Vestea Bună\" din București, profesor universitar la Institutul Teologic Penticostal și conducător de doctorat la Universitatea din București. În peste douăzeci de ani de activitate pastorală și academică a scris numeroase articole teologice, a ținut prelegeri la conferințe și a publicat cărți despre studiul Scripturii și păstorirea Bisericii: Viața Bisericii conform metaforelor pauline, Conducerea bisericească în scrierile Noului Testament, Epistola către Coloseni, De la text la predică, Odihnă la umbra Celui Atotputernic, Meditații biblice din vremea pandemiei.", "/images/unbound/2024/ciprian-terinte.png")}>
                    <h4>Sesiune plenară</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/ciprian-terinte.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Ciprian Terinte</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                </div>
                <div className="guide-slot">
                  <p>19:30 - 20:00</p>
                  <div className="guide-slot_card">
                    <h4>
                      Q&A - Radu Cîmpean & Ciprian Terinte
                    </h4>
                  </div>
                </div>
                <div className="guide-slot">
                  <p>20:00 - 21:00</p>
                  <div className="guide-slot_card">
                    <h4>Agapă</h4>
                  </div>
                </div>
              </div>
            </section>

            <hr className="divider" />
            <section className="schedule-stage">
              <div className="schedule-stage__title">
                <h3>Sâmbătă</h3>
              </div>
              <div className="schedule-stage_guide-container">
                <div className="guide-slot">
                  <p>9:00 - 10:00</p>
                  <div className="guide-slot_card">
                    <h4>Welcome & Coffee </h4>
                  </div>
                </div>
                <div className="guide-slot">
                  <p>10:00 - 10:40</p>
                  <div className="guide-slot_card">
                    <h4>Laudă și închinare</h4>
                  </div>
                </div>
                <div className="guide-slot">
                  <p>11:00 - 12:00 </p>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Sola Fide", "Silviu Cornea", "Seminarul meu, Sola Fide (numai credința), își propune să definească credința așa cum e ea definită de Scriptură și cum înțelegerea credinței într-un mod biblic și corect va defini modul în care ne vom trăi în mod autentic viața de credință!", "/images/unbound/2024/silviu-cornea.png")}>
                    <h4>Sola Fide</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/silviu-cornea.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Silviu Cornea</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Te iert, dar nu te uit!", "Teofil Cotrău", "Este iertarea o simplă alegere sau un proces care implică și emoțiile noastre? \n Fiind persoane cu terminații nervoase interpersonale, nici unul dintre noi nu este imun în fața rănilor. De multe ori descoperim că deși am ales să iertăm, totuși ne trezim cu emoții negative. Înseamnă că n-am iertat? Ce-i de făcut atunci? Atelierul își propune o abordare interogativă și integrativă din punct de vedere Biblic, pastoral și experiențial a iertării creștine.", "/images/unbound/2024/teofil-cotrau.png")}>
                    <h4>Te iert, dar nu te uit!</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/teofil-cotrau.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Teofil Cotrău</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Big Bang și Creație: Compatibilitate între Știință și Credință?", "Caius Obedea", "Alinierea teoriei Big Bang-ului cu relatarea creației din Geneza a generat dezbateri intense, dar mulți teologi și oameni de știință susțin compatibilitatea celor două perspective. Teoria Big Bang-ului sugerează un început brusc al universului, similar cu creația ex nihilo din Geneza, iar expresia “să fie lumină” poate reflecta acest moment. Istoric, mulți oameni de știință importanți, inclusiv dezvoltatorii teoriei Big Bang, erau creștini, subliniind armonia dintre știință și credință. Deși modernismul și postmodernismul au promovat o separare, compatibilitatea reală între știință și credință îndreaptă privirile spre un Creator.", "/images/unbound/2024/caius-obedea.png")}>
                    <h4>Big Bang și Creație: Compatibilitate între Știință și Credință?</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/caius-obedea.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Caius Obedea</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Conținutul Credinței Autentice", "Gabriel Moisuc", "Ce face ca o credință sa fie autentica și alta falsă?", "/images/unbound/2024/gabriel-moisuc.png")}>
                    <h4>Conținutul Credinței Autentice</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/gabriel-moisuc.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Gabriel Moisuc</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Unicitatea credinței creștine în contextul pluralismului de azi", "Daniel Cioban", "Într-o lume din ce în ce mai diversă, în care pluralismul religios și cultural este tot mai prezent, credința creștină se confruntă cu noi provocări și întrebări. \n Cum poate creștinismul să își mențină identitatea și relevanța într-un context în care multiple sisteme de credință coexistă și se intersectează? \n Atelierul „Unicitatea credinței creștine în contextul pluralismului de azi” își propune să ofere participanților un spațiu de reflecție asupra fundamentelor credinței creștine și resurse teologice și practice pentru a naviga în pluralismul religios de azi fără a compromite unicitatea mesajul creștin.", "/images/unbound/2024/daniel-cioban.png")}>
                    <h4>Unicitatea credinței creștine în contextul pluralismului de azi</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/daniel-cioban.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Daniel Cioban</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Credință și Comunitate vs. Credința „lupului singuratic”", "Samuel Tuțac", "Dacă biserica este singura instituție pe care Cristos a întemeiat-o și a promis să o susțină; de ce ar vrea cineva care pretinde că Îl iubește pe Domnul să stea departe de poporul Lui? Ideea creștinilor care trăiesc independent de biserică este pur și simplu străină de Noul Testament.", "/images/unbound/2024/samuel-tutac.png")}>
                    <h4>Credință și Comunitate vs. Credința „lupului singuratic”</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/samuel-tutac.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Samuel Tuțac</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                </div>
                <div className="guide-slot">
                  <p>12:00 - 12:30</p>
                  <div className="guide-slot_card">
                    <h4>Pauză</h4>
                  </div>
                </div>
                <div className="guide-slot">
                  <p>12:30 - 13:30 </p>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Sola Fide", "Silviu Cornea", "Seminarul meu, Sola Fide (numai credința), își propune să definească credința așa cum e ea definită de Scriptură și cum înțelegerea credinței într-un mod biblic și corect va defini modul în care ne vom trăi în mod autentic viața de credință!", "/images/unbound/2024/silviu-cornea.png")}>
                    <h4>Sola Fide</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/silviu-cornea.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Silviu Cornea</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Te iert, dar nu te uit!", "Teofil Cotrău", "Este iertarea o simplă alegere sau un proces care implică și emoțiile noastre? \n Fiind persoane cu terminații nervoase interpersonale, nici unul dintre noi nu este imun în fața rănilor. De multe ori descoperim că deși am ales să iertăm, totuși ne trezim cu emoții negative. Înseamnă că n-am iertat? Ce-i de făcut atunci? Atelierul își propune o abordare interogativă și integrativă din punct de vedere Biblic, pastoral și experiențial a iertării creștine.", "/images/unbound/2024/teofil-cotrau.png")}>
                    <h4>Te iert, dar nu te uit!</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/teofil-cotrau.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Teofil Cotrău</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Big Bang și Creație: Compatibilitate între Știință și Credință?", "Caius Obedea", "Alinierea teoriei Big Bang-ului cu relatarea creației din Geneza a generat dezbateri intense, dar mulți teologi și oameni de știință susțin compatibilitatea celor două perspective. Teoria Big Bang-ului sugerează un început brusc al universului, similar cu creația ex nihilo din Geneza, iar expresia “să fie lumină” poate reflecta acest moment. Istoric, mulți oameni de știință importanți, inclusiv dezvoltatorii teoriei Big Bang, erau creștini, subliniind armonia dintre știință și credință. Deși modernismul și postmodernismul au promovat o separare, compatibilitatea reală între știință și credință îndreaptă privirile spre un Creator.", "/images/unbound/2024/caius-obedea.png")}>
                    <h4>Big Bang și Creație: Compatibilitate între Știință și Credință?</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/caius-obedea.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Caius Obedea</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Conținutul Credinței Autentice", "Gabriel Moisuc", "Ce face ca o credință sa fie autentica și alta falsă?", "/images/unbound/2024/gabriel-moisuc.png")}>
                    <h4>Conținutul Credinței Autentice</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/gabriel-moisuc.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Gabriel Moisuc</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Unicitatea credinței creștine în contextul pluralismului de azi", "Daniel Cioban", "Într-o lume din ce în ce mai diversă, în care pluralismul religios și cultural este tot mai prezent, credința creștină se confruntă cu noi provocări și întrebări. \n Cum poate creștinismul să își mențină identitatea și relevanța într-un context în care multiple sisteme de credință coexistă și se intersectează? \n Atelierul „Unicitatea credinței creștine în contextul pluralismului de azi” își propune să ofere participanților un spațiu de reflecție asupra fundamentelor credinței creștine și resurse teologice și practice pentru a naviga în pluralismul religios de azi fără a compromite unicitatea mesajul creștin.", "/images/unbound/2024/daniel-cioban.png")}>
                    <h4>Unicitatea credinței creștine în contextul pluralismului de azi</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/daniel-cioban.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Daniel Cioban</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Credință și Comunitate vs. Credința „lupului singuratic”", "Samuel Tuțac", "Dacă biserica este singura instituție pe care Cristos a întemeiat-o și a promis să o susțină; de ce ar vrea cineva care pretinde că Îl iubește pe Domnul să stea departe de poporul Lui? Ideea creștinilor care trăiesc independent de biserică este pur și simplu străină de Noul Testament.", "/images/unbound/2024/samuel-tutac.png")}>
                    <h4>Credință și Comunitate vs. Credința „lupului singuratic”</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/samuel-tutac.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Samuel Tuțac</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                </div>
                <div className="guide-slot">
                  <p>13:30 - 14:30 </p>
                  <div className="guide-slot_card">
                    <h4>Pizza</h4>
                  </div>
                </div>
                <div className="guide-slot">
                  <p>14:30 - 15:30 </p>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Sola Fide", "Silviu Cornea", "Seminarul meu, Sola Fide (numai credința), își propune să definească credința așa cum e ea definită de Scriptură și cum înțelegerea credinței într-un mod biblic și corect va defini modul în care ne vom trăi în mod autentic viața de credință!", "/images/unbound/2024/silviu-cornea.png")}>
                    <h4>Sola Fide</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/silviu-cornea.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Silviu Cornea</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Te iert, dar nu te uit!", "Teofil Cotrău", "Este iertarea o simplă alegere sau un proces care implică și emoțiile noastre? \n Fiind persoane cu terminații nervoase interpersonale, nici unul dintre noi nu este imun în fața rănilor. De multe ori descoperim că deși am ales să iertăm, totuși ne trezim cu emoții negative. Înseamnă că n-am iertat? Ce-i de făcut atunci? Atelierul își propune o abordare interogativă și integrativă din punct de vedere Biblic, pastoral și experiențial a iertării creștine.", "/images/unbound/2024/teofil-cotrau.png")}>
                    <h4>Te iert, dar nu te uit!</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/teofil-cotrau.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Teofil Cotrău</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Big Bang și Creație: Compatibilitate între Știință și Credință?", "Caius Obedea", "Alinierea teoriei Big Bang-ului cu relatarea creației din Geneza a generat dezbateri intense, dar mulți teologi și oameni de știință susțin compatibilitatea celor două perspective. Teoria Big Bang-ului sugerează un început brusc al universului, similar cu creația ex nihilo din Geneza, iar expresia “să fie lumină” poate reflecta acest moment. Istoric, mulți oameni de știință importanți, inclusiv dezvoltatorii teoriei Big Bang, erau creștini, subliniind armonia dintre știință și credință. Deși modernismul și postmodernismul au promovat o separare, compatibilitatea reală între știință și credință îndreaptă privirile spre un Creator.", "/images/unbound/2024/caius-obedea.png")}>
                    <h4>Big Bang și Creație: Compatibilitate între Știință și Credință?</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/caius-obedea.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Caius Obedea</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Conținutul Credinței Autentice", "Gabriel Moisuc", "Ce face ca o credință sa fie autentica și alta falsă?", "/images/unbound/2024/gabriel-moisuc.png")}>
                    <h4>Conținutul Credinței Autentice</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/gabriel-moisuc.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Gabriel Moisuc</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Unicitatea credinței creștine în contextul pluralismului de azi", "Daniel Cioban", "Într-o lume din ce în ce mai diversă, în care pluralismul religios și cultural este tot mai prezent, credința creștină se confruntă cu noi provocări și întrebări. \n Cum poate creștinismul să își mențină identitatea și relevanța într-un context în care multiple sisteme de credință coexistă și se intersectează? \n Atelierul „Unicitatea credinței creștine în contextul pluralismului de azi” își propune să ofere participanților un spațiu de reflecție asupra fundamentelor credinței creștine și resurse teologice și practice pentru a naviga în pluralismul religios de azi fără a compromite unicitatea mesajul creștin.", "/images/unbound/2024/daniel-cioban.png")}>
                    <h4>Unicitatea credinței creștine în contextul pluralismului de azi</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/daniel-cioban.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Daniel Cioban</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Credință și Comunitate vs. Credința „lupului singuratic”", "Samuel Tuțac", "Dacă biserica este singura instituție pe care Cristos a întemeiat-o și a promis să o susțină; de ce ar vrea cineva care pretinde că Îl iubește pe Domnul să stea departe de poporul Lui? Ideea creștinilor care trăiesc independent de biserică este pur și simplu străină de Noul Testament.", "/images/unbound/2024/samuel-tutac.png")}>
                    <h4>Credință și Comunitate vs. Credința „lupului singuratic”</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/samuel-tutac.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Samuel Tuțac</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                </div>
                <div className="guide-slot">
                  <p>15:30 - 18:00</p>
                  <div className="guide-slot_card">
                    <h4>
                      Timp liber
                    </h4>
                  </div>
                </div>
                <div className="guide-slot">
                  <p>18:00 - 18:40</p>
                  <div className="guide-slot_card">
                    <h4>
                      Laudă și închinare
                    </h4>
                  </div>
                </div>
                <div className="guide-slot">
                  <p>18:40 - 19:30</p>
                  <div className="guide-slot_card-clickable"
                    onClick={() => openModal("Sesiune plenară", "Radu Cîmpean", "Slujesc ca pastor din anul 2003 în biserica Betania și tot din acel an sunt implicat în predarea cursurilor teologice la Liceul Teologic Betania din Sibiu. Pe lângă aceste slujiri, sunt binecuvântat de Dumnezeu cu o familie frumoasă, Ema, soția și cei doi copii Filip și Daria. Motivația supremă în viață este ca la finalul alergării să aud cuvintele Mântuitorului: Bine, rob bun și credincios! ", "/images/unbound/2024/radu-cimpean.png")}>
                    <h4>Sesiune plenară</h4>
                    <figure className="author-wrapper">
                      <picture>
                        <img
                          src="/images/unbound/2024/radu-cimpean.png"
                          alt=""
                        />
                      </picture>
                      <figcaption>Radu Cîmpean</figcaption>
                    </figure>
                    <img style={{ marginLeft: "160px", marginBottom: "-25px" }} width="17" height="17" src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png" alt="info" />
                  </div>
                </div>
                <div className="guide-slot">
                  <p>19:30 - 20:15</p>
                  <div className="guide-slot_card">
                    <h4>
                      Concluzii
                    </h4>
                  </div>
                </div>
              </div>
            </section>
            <hr className="divider" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnboundContainer;
