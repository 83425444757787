import React, { useEffect, useRef } from 'react';

const InstagramModal = ({ item, onClose }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (item.media_type === 'VIDEO' && videoRef.current) {
      videoRef.current.currentTime = item.currentTime || 0; // Use the passed currentTime or default to 0
      videoRef.current.play().catch(error => {
        console.error('Video play error:', error);
      });
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, [item, onClose]);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" style={{ pointerEvents: 'auto' }}>
        <div className="modal-header">
          <button className="modal-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          {item.media_type === 'IMAGE' && (
            <img src={item.media_url} alt={item.caption} className="modal-image modal-image-small" onClick={e => e.stopPropagation()} />
          )}
          {item.media_type === 'VIDEO' && (
            <video
              ref={videoRef}
              src={item.media_url}
              className="modal-video responsive-media"
              controls
              onClick={e => e.stopPropagation()}
            >
              Your browser does not support the video tag.
            </video>
          )}
          {item.media_type === 'CAROUSEL_ALBUM' && item.children && (
            <div className="carousel-container">
              {item.children.data.map(child => (
                <img key={child.id} src={child.media_url} alt={child.caption} className="carousel-image" />
              ))}
            </div>
          )}

          <p className="modal-caption" onClick={e => e.stopPropagation()}>{item.caption}</p>
        </div>
      </div>
      <button className="modal-close" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default InstagramModal;
