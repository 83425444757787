import React from 'react';


const EventDetailsInner = ( {description }) => {
    return (
        <div className="project-details_inner">
            <div className="post_content">
                <div className="fulltext">
                    <h3 className="widget-title">
                        Descriere
                        <span className="title-line"></span>
                    </h3>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </div>
            </div>
        </div>
    );
};

export default EventDetailsInner;