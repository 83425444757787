import $ from "jquery";
import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import GalleryCard from "../../components/ProjectElements/GalleryCard";
import SectionGridLines from "../../components/SectionGridLines";
import SectionHeader from "../../components/SectionHeader";
import FullscreenImage from '../../components/FullscreenImage'; // Path to your FullscreenImageOverlay component
import Paragraph from '../../components/Paragraph';
import GalleryData from '../../galleryData.json';


const GalleryMedia = () => {

  const [filterKey, setFilterKey] = useState('seri')
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [filteredImages, setFilteredImages] = useState(GalleryData);
  const [showPlaylist, setShowPlaylist] = useState(false); // Add state for the playlist
  

  const handleFullscreenImageOpen = (src) => {
    setFullscreenImage(src);
  };

  const handleFullscreenImageClose = () => {
    setFullscreenImage(null);
  };

  //Set clicked button to checked
  $(function () {
    $(".button").on("click", function (e) {  // selects any element
      //e.stopPropagation(); // stops click event from bubbling up from child
      $(".button").removeClass("is-checked"); // remove all previously selected classes
      $(this).addClass("is-checked"); // add our new class
    });
  })

  // handling filter key change
  useEffect(() => {
    setFilteredImages(
      GalleryData.sort((a, b) => b.id - a.id).filter((data) => data.tag === filterKey)
    );
  }, [filterKey]);

  const handleFilterKeyChange = (key) => () => {
    setFilterKey(key);

    // Reset the playlist display when changing filters
    setShowPlaylist(false);
  };

  const handleShowPlaylist = () => {
    // Toggle the playlist display
    setShowPlaylist(!showPlaylist);
  };

  return (
    <section className="projects packery">
      <SectionGridLines></SectionGridLines>
      <div className="large_font">
        <SectionHeader title="Galerie"></SectionHeader>
      </div>
      <div className="container">
        <div className="section-header text-center has_line">
          <PageHeader className="text-white" title="Media"></PageHeader>
          <div className="section-desc row align-items-center justify-content-center">
            {/* <div className="col-lg-6 text-end">
              <Paragraph text="Aruncă o privire la ce se petrece în cardul comunității Vox Dei."></Paragraph>
            </div> */}
            <div className="col-lg-6 text-center">
              <Paragraph text="Aruncă o privire la ce se petrece în cardul comunității Vox Dei."></Paragraph>
            </div>
          </div>
        </div>

        <div className="portfolio-filters-content">
          <div className="filters-button-group">
          <button
            className={`button ${filterKey === "seri" ? "is-checked" : ""}`}
            onClick={handleFilterKeyChange("seri")}
          >
            Serile Vox Dei <sup className="filter-count"></sup>
          </button>
          <button
            className={`button ${filterKey === "tabere" ? "is-checked" : ""}`}
            onClick={handleFilterKeyChange("tabere")}
          >
            Tabere <sup className="filter-count"></sup>
          </button>
          <button
            className={`button ${filterKey === "activitatiOutdoor" ? "is-checked" : ""}`}
            onClick={handleFilterKeyChange("activitatiOutdoor")}
          >
            Activități Outdoor <sup className="filter-count"></sup>
          </button>
          <button
            className={`button ${filterKey === "activitatiIndoor" ? "is-checked" : ""}`}
            onClick={handleFilterKeyChange("activitatiIndoor")}
          >
            Activități Indoor <sup className="filter-count"></sup>
          </button>
          {/* Add the "videoclipuri" button */}
          <button
            className={`button ${showPlaylist ? "is-checked" : ""}`}
            onClick={handleShowPlaylist}
          >
            Videoclipuri
          </button>
          </div>
        </div>
        {fullscreenImage && (
          <FullscreenImage
            src={fullscreenImage}
            onClose={handleFullscreenImageClose}
          />
        )}
      </div>
      {showPlaylist ? (
        <div className="youtube-playlist">
          {/* You can embed the YouTube playlist here */}
          <iframe 
            className='iframe-youtube' 
            width="560" 
            height="315"
            src="https://www.youtube.com/embed/YF02hMptA3I?si=jde3RC_lYRTeRdCD" 
            title="Vox Deo - YouTube videos" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen>
          </iframe>
        </div>
      ) : (
        // Conditional rendering for the image gallery
        <div className="gallery-grid">
          {filteredImages.map((data) => (
            <GalleryCard
              key={data.id}
              data={data}
              onImageClick={handleFullscreenImageOpen}
              className="grid-item"
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default GalleryMedia;
