import $ from "jquery";
import React, { useEffect } from "react";
import UpcomingEventsItem from "../components/ProjectElements/UpcomingEventsItem";
import SectionGridLines from "../components/SectionGridLines";

const UpcomingEvents = () => {
  useEffect(() => {
    // Alternate Hover/Active
    $(".left_part .grid-item, .right_part .grid-item").hover(
      function () {
        $(".right_part .grid-item").removeClass("active");
      },
      function () {
        $(".right_part .grid-item + .grid-item").addClass("active");
      }
    );
  }, []);

  return (
    <section className="running_projects">
      <SectionGridLines />
      <div className="large_font">
        <h2 className="floating_element text-dark-200 d-flex justify-content-center">
          Evenimente
        </h2>
        <h3 className='future-events text-white'>Upcoming</h3>
      </div>
      <div className="container">
        <div className="row justify-content-between mobile-order">
          <div className="col-lg-5 col-md-5 col-sm-5">
            <div className="left_part">
              <div className="grid-item">

                {/* Third */}
                <UpcomingEventsItem
                  img="/images/bg/2025/summer-camp-2025.webp"
                  name="Summer Camp 2025"
                  details="Stay tuned!"
                ></UpcomingEventsItem>
              </div>
            </div>
          </div>

          {/* First */}
          <div className="col-lg-5 col-md-5 col-sm-5">
            <div className="right_part mobile-right-part">
              <div className="grid-item">
                <UpcomingEventsItem
                  img="/images/bg/2024/unbound-2024.webp"
                  name="Unbound - Empowered by Faith"
                  details="Într-o lume tot mai întunecata in care totul are de a face cu percepția ochilor, Credința este lumina care ne ghidează pașii în întuneric."
                ></UpcomingEventsItem>
              </div>

              {/* Second */}
              <div className="grid-item">
                <UpcomingEventsItem
                  img="/images/bg/2024/season-start.webp"
                  name="Season Start 2024"
                  details="Începem serile Vox Dei din 22 octombrie!"
                ></UpcomingEventsItem>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvents;
