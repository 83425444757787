import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";
// import AboutUs from "../Pages/AboutUs";
// import CommingSoon from "../Pages/CommingSoon";
// import Contact from "../Pages/Contact";
import ErrorPage from "../Pages/ErrorPage";
import HomePage from "../Pages/HomePages/HomePage";
import UnboundPage from "../Pages/HomePages/UnboundPage";
import MediaPage from "../Pages/HomePages/MediaPage";
import EventsPage from "../Pages/EventsPages/EventsPage";
import EventDetails from "../Pages/EventsPages/EventDetails";
import HealthCheck from "../Pages/HealthCheck";

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <HomePage/>,
            },
            {
                path: '/media',
                element: <MediaPage/>,
            },
            {
                path: '/event-details/:eventTitle', // Add the dynamic parameter here
                element: <EventDetails />,
            },

            {
                path: '/events',
                element: <EventsPage/>,
            },
          
            // {
            //     path: '/coming-soon',
            //     element: <CommingSoon/>,
            // },
            // {
            //     path: '/contact',
            //     element: <Contact/>,
            // },
            // {
            //     path: '/about',
            //     element: <AboutUs/>,
            // },
      
            {
                path: '/unbound',
                element: <UnboundPage />,
            },
            {
                path: '*',
                element: <ErrorPage/>,
            },
            {
                path: '/healthcheck',
                element: <HealthCheck/>,
            },

        ]
    },
])