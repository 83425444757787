import React from 'react';

const HomepageSliderCard = ({ data }) => {
    const { bgImg } = data;
    return (
        <div className="slider" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="slide_content" style={{ marginTop: "80px"}}>
                <div className="slide_content_wrapper mb-0 h-auto bg-dark-100">
                    <div className="slide_content_inner">
                        <div className="meta m-0">
                            <div className="category text-olive text-uppercase">Vox Dei</div>
                        </div>
                        <h4 className="text-white">Participă la întâlnirea Vox Dei în fiecare marți, ora 19:00</h4>
                        {/* <form action="/unbound" style={{ paddingRight: window.innerWidth < 768 ? "20px":""}}>
                        <button className="unbound-button" type='submit'>
                            <span className="link_text" >Înscriere</span>
                        </button>
                        </form> */}
                        <div className="details_link">
                            <span className="link_icon">
                                <span className="line"></span>
                                <span className="circle"></span>
                                <span className="dot"></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomepageSliderCard;