import React from 'react';
import UnboundContainer from '../../containers/ProjectContainers/UnboundContainer';
import UnboundSlider from '../../containers/SliderContainers/UnboundSlider';
import AboutUnbound from "../../containers/AboutContainers/AboutUnbound";

const UnboundPage = () => {
    document.title = "Vox Dei - Unbound";

    return (
        <div className="wrapper">
            <UnboundSlider />
            <AboutUnbound />
            <UnboundContainer /> {/* No conditional rendering, always loaded */}
        </div>
    );
};

export default UnboundPage;
