import React from 'react';
import EventsContainer from '../../containers/EventsContainer';

const EventsPage = () => {
    document.title = "Vox Dei - Events";
    
    return (
        <>
            <EventsContainer />
        </>
    );
};

export default EventsPage;