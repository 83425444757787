import { RouterProvider } from 'react-router-dom';
import 'react-select2-wrapper/css/select2.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './App.css';
import { routes } from './Routes/Router';
import './assets/css/style.css';

function App() {
//   window.addEventListener('error', (event) => {
//     console.error("Global Error caught:", event.message);
// });

// window.addEventListener('unhandledrejection', (event) => {
//     console.error("Unhandled promise rejection:", event.reason);
// });
  return (
    <div>
      <RouterProvider router={routes} /> 
    </div>
  );
}

export default App;
