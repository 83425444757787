import React from 'react';
import EventDetailsContainer from '../../containers/ProjectContainers/EventDetailsContainer';

const EventDetails = () => {
    document.title = "Vox Dei - Events";
    
    return (
        <main className="wrapper">
            <EventDetailsContainer />
        </main>
    );
};

export default EventDetails;