import React from "react";

const FullscreenImage = ({ src, onClose }) => {
  return (
    <div className="fullscreen-overlay" onClick={onClose}>
      <img src={src} alt="Fullscreen" className="fullscreen-image" />
    </div>
  );
};

export default FullscreenImage;