import React, { useState } from "react";
import EventCard from "../components/EventCard";
import SectionHeader from "../components/SectionHeader";
import PageHeader from "../components/PageHeader";
import Paragraph from '../components/Paragraph';

const EventsContainer = () => {

  const eventCards = [
    // {
    //   img01: "/images/bg/2024/wintercamp-2024.webp",
    //   img02: "/images/main-logo-cropped.webp",
    //   title: "Winter Camp 2024",
    //   text: "Avem deja planuri pentru noul an!🔥 Vox Dei organizează un weekend la munte, în perioada 01-03.03.2024 la Casa Elim, Zărnești."
    // },
    {
      img01: "/images/bg/2024/season-start.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Season Start 2024",
      text: "Începem serile Vox Dei din 22 octombrie!"
    },
    {
      img01: "/images/bg/2024/unbound-2024.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Unbound - Empowered by Faith",
      text: "Într-o lume tot mai întunecata in care totul are de a face cu percepția ochilor, Credința este lumina care ne ghidează pașii în întuneric."
    },
    {
      img01: "/images/bg/2024/summer-camp-2024.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Summer Camp 2024",
      text: "Dorim să repetăm experiența din anii anteriori, de aceea, această tabără va avea loc în aceeași locație, și anume, în județul 𝐇𝐚𝐫𝐠𝐡𝐢𝐭𝐚, în Vlăhița."
    },
    {
      img01: "/images/bg/2023/colindat-2023.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Colindat 2023",
      text: "Cum ne petrecem noi Crăciunul? Ducând vestea bună și sărbătorind nașterea Pruncului prin cântec."
    },
    {
      img01: "/images/bg/2023/unbound-2023.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Unbound - Empowered by Scripture",
      text: "𝐘𝐞𝐬, 𝐢𝐭’𝐬 𝐡𝐚𝐩𝐩𝐞𝐧𝐢𝐧𝐠🥳 🥁Dăm startul înscrierilor pentru conferința UNBOUND! 🥁"
    },
    {
      img01: "/images/bg/2023/summer-camp.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Summer Camp 2023",
      text: "Dorim să repetăm experiența din anul anterior, de aceea, această tabără va avea loc în aceeași locație, și anume, în județul 𝐇𝐚𝐫𝐠𝐡𝐢𝐭𝐚, în Vlăhița."
    },
    {
      img01: "/images/bg/2023/eveniment-hiking.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Going Hiking, Vânătoarea lui Buteanu",
      text: "Așa cum v-am obișnuit, locația este una dintre cele mai frumoase și de data aceasta vom ajunge pe vârful Vânătoarea lui Buteanu (2507 metri)"
    },
    {
      img01: "/images/bg/2023/board-games.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Board Games Night",
      text: "Îți este dor de Vox Dei și vrei să petreci o seară distractivă în compania prietenilor și să joci cele mai captivante jocuri de societate? Atunci vino la Board Games Night!"
    },
    {
      img01: "/images/bg/2023/gaming-night.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Gaming Night",
      text: "Te anunțăm că 𝐯𝐢𝐧𝐞𝐫𝐢, 𝟐𝟑 𝐢𝐮𝐧𝐢𝐞, începând cu ora 𝟏𝟗:𝟎𝟎, 𝐕𝐨𝐱 𝐃𝐞𝐢 organizează un 𝐥𝐚𝐧-𝐩𝐚𝐫𝐭𝐲 special. Așadar, vei avea oportunitatea de a te bucura de 𝐠𝐚𝐦𝐢𝐧𝐠 într-un mediu cu totul deosebit și prietenos."
    },
    // {
    //   img01: "/images/bg/voxdei-volley.webp",
    //   img02: "/images/main-logo-cropped.webp",
    //   title: "Volley",
    //   text: "În fiecare luni și joi de la ora 22:00"
    // },
    {
      img01: "/images/bg/2023/hiking-tarnita.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Let's Go Hiking, Tărnița",
      text: "Te invităm să ni te alături unei drumeții unice în mijlocul naturii, într-un traseu care începe de la 𝐇𝐢𝐝𝐫𝐨𝐜𝐞𝐧𝐭𝐫𝐚𝐥𝐚 𝐓𝐚𝐫𝐧𝐢𝐭𝐚 până la 𝐏𝐢𝐚𝐭𝐫𝐚 𝐥𝐮𝐢 𝐋𝐮𝐜𝐚𝐜𝐢."
    },
    {
      img01: "/images/bg/2023/unumai.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "1 Mai",
      text: "Dacă și tu dorești să te recreezi, dar și să ai parte de un timp distractiv, de discuții și jocuri în echipă, avem o veste bună pentru tine! Organizăm o ieșire care va avea loc în data de 𝟏 𝐦𝐚𝐢, la Valea Moașei."
    },
    {
      img01: "/images/bg/2023/worship-night.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Worship Night",
      text: "𝐋𝐞𝐭'𝐬 𝐰𝐨𝐫𝐬𝐡𝐢𝐩 𝐆𝐨𝐝!🔥 Dorim tot mai mult să Îl lăudăm pe Dumnezeu și să ne închinăm Lui, dar nu oricum, ci împreună cu voi!"
    },
    {
      img01: "/images/bg/2023/lumos-2023.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Lumos Meeting 2023",
      text: "Într-un cadru mai restrâns, cu o atmosferă friendly, ne vom bucura împreună de câteva momente valoroase."
    },
    {
      img01: "/images/bg/2023/voxdei-volley.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Campionat de Volley",
      text: "𝐖𝐢𝐧𝐧𝐞𝐫 𝐨𝐫 𝐥𝐨𝐬𝐞𝐫?🤔"
    },
    {
      img01: "/images/bg/2023/winter-camp-2023.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Winter Camp 2023",
      text: "Deconectați de rutina zilnică, conectați cu Dumnezeu și tineri faini!🔥 Ne vom bucura de un weekend marca Vox Dei, la 📍cabana Alpiniș.🤍"
    },
    {
      img01: "/images/bg/2023/movie-night.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Movie Night",
      text: "Parcă niciodată nu există un moment inoportun pentru un film bun, cu atât mai mult când creezi atmosfera perfectă și ești înconjurat de oameni faini!"
    },
    {
      img01: "/images/bg/2022/colindat-2022.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Colindat 2022",
      text: "Cum ne petrecem noi Crăciunul? Ducând vestea bună și sărbătorind nașterea Pruncului prin cântec. Domnul sa fie laudat!"
    },
    {
      img01: "/images/bg/2022/unbound-2022.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Unbound 2022",
      text: "Știi ce urmează? Prima conferință națională organizată de tinerii VoxDei! UNBOUND✨"
    },
    {
      img01: "/images/bg/2022/seara-socializare-2022.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Seară Socializare 2022",
      text: "Sunteți pregătiți pentru o seară de socializare VoxDei? Voi aduceți un good vibe iar noi ne ocupăm de Coffee & Tea."
    },
    {
      img01: "/images/bg/2022/going-hiking-2.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Going Hiking, Rozdesti",
      text: "Ne-am decis sa organizăm o nouă drumeție, de data aceasta traseul fiind: Paltinis-Saua Batrana-vf Rozdesti."
    },
    {
      img01: "/images/bg/2022/going-hiking.webp",
      img02: "/images/main-logo-cropped.webp",
      title: "Going Hiking, Mălăiești",
      text: "Mergem până la cabana Mălăiești, Râșnov, cu VoxDei!"
    },
  ];

  const cardsPerPage = 6;
  const totalPages = Math.ceil(eventCards.length / cardsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  
    // Add the transitioning class
    const paginationItems = document.querySelectorAll('.pagination-item');
    paginationItems.forEach(item => item.classList.add('transitioning'));
  
    setTimeout(() => {
      // Remove the transitioning class after the animation
      paginationItems.forEach(item => item.classList.remove('transitioning'));
      
      setCurrentPage(newPage);
    }, 300); // Wait for 300ms, change it to match your CSS transition duration
  };
  
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  const visibleEventCards = eventCards.slice(startIndex, endIndex);

  return (
    <main className="projects packery">
      <section className="services inner in-service pb-0">
        <div className="container">
          <div className="section-header text-center has_line">
            <PageHeader className="text-white" title="Evenimente"></PageHeader>
            <div className="section-desc row align-items-center justify-content-center">
              {/* <div className="col-lg-6 text-end">
              <Paragraph text="Aruncă o privire la ce se petrece în cardul comunității Vox Dei."></Paragraph>
            </div> */}
              <div className="col-lg-6 text-center">
                <Paragraph text="Aici găsești o listă cu ultimele evenimente organizate de către noi. Te invităm să participi și tu!"></Paragraph>
              </div>
            </div>
          </div>
          <div className="large_font">
            <SectionHeader title="Evenimente"></SectionHeader>
          </div>
          <div className="row">
        {visibleEventCards.map((eventCard, index) => (
          <div className="col-lg-4" key={index}>
            <EventCard
              img01={eventCard.img01}
              img02={eventCard.img02}
              title={eventCard.title}
              text={eventCard.text}
            />
          </div>
        ))}
      </div>

      {totalPages > 1 && (
        <div className="pagination">
          {Array.from({ length: totalPages }).map((_, index) => (
            <span
              key={index}
              className={`pagination-item ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </span>
          ))}
        </div>
      )}
      </div>
      </section>
    </main>
  );
};

export default EventsContainer;
