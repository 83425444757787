import React from 'react';
import AboutContainer from '../../containers/AboutContainers/AboutContainer';
// import GalleryHomepage from '../../containers/ProjectContainers/GalleryHomepage';
import UpcomingEvents from '../../containers/UpcomingEvents';
import HomepageSlider from '../../containers/SliderContainers/HomepageSlider';
import InstagramFeed from '../../components/InstagramFeed';
import YoutubeVideo from '../../components/YoutubeVideo';

const HomePage = () => {
    document.title = "Vox Dei - Home";

    return (
        <div className="wrapper">
            <HomepageSlider />
            <AboutContainer />
            <YoutubeVideo />
            <UpcomingEvents />
            <InstagramFeed /> {/* Always render InstagramFeed */}
            {/* <GalleryHomepage /> */}
        </div>
    );
};

export default HomePage;
