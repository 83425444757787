import React, { useEffect } from 'react';

const AboutContainer = () => {

  return (
    <section className="about style_two">
      <div className="large_font">
        <h2 className="floating_element text-dark-200 d-flex justify-content-center">
          About
        </h2>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-5 col-sm-6">
            <div className="about_image" style={{ maxWidth: "85%" }}>
              {/* <img src="images/about/e1.svg" alt="About" className="line_frame_2" /> */}
              <img src="/images/unbound/2023/06.webp" alt="img" className="abt_img_1" />
              <img src="/images/unbound/2023/01.webp" alt="img" className="abt_img_2" />
              <img
                src="/images/unbound/2023/15.webp"
                alt="About"
                className="line_frame_1"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-7 col-sm-6">
            <div className="about_text_inner">
              <div className="about_text mt-5">
                <h2 className="text-white">UNBOUND &nbsp; EMPOWERED BY FAITH</h2>
                <p>
                  Într-o lume tot mai întunecata in care totul are de a face cu percepția ochilor, Credința este lumina care ne ghidează pașii în întuneric,
                  iar umblarea prin credință înseamnă a merge înainte cu inima încrezătoare, știind că Dumnezeu ne poartă de grijă în fiecare clipă,
                  chiar și atunci când nu vedem calea. Încrederea în Dumnezeu ne dă curajul să pășim fără teamă, știind că promisiunile Sale sunt mai sigure
                  decât orice vedem cu ochii.
                </p>
              </div>

              <div className="about_progress_box">
                <div className="progress-classic position-relative mb-4">
                  <div className="progress-label fw-semibold mb-2">Worship</div>
                  <div
                    className="progress overflow-visible"
                    style={{ height: "4px" }}
                  >
                    <div
                      className="progress-bar overflow-visible"
                      role="progressbar"
                      aria-label="Red example"
                      style={{ width: "25%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span className="progress-value fw-semibold">25%</span>
                    </div>
                  </div>
                </div>

                <div className="progress-classic position-relative mb-4">
                  <div className="progress-label fw-semibold mb-2">Learning</div>
                  <div
                    className="progress overflow-visible"
                    style={{ height: "4px" }}
                  >
                    <div
                      className="progress-bar overflow-visible"
                      role="progressbar"
                      aria-label="Red example"
                      style={{ width: "25%" }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span className="progress-value fw-semibold">25%</span>
                    </div>
                  </div>
                </div>

                <div className="progress-classic position-relative mb-4">
                  <div className="progress-label fw-semibold mb-2">Socializing</div>
                  <div
                    className="progress overflow-visible"
                    style={{ height: "4px" }}
                  >
                    <div
                      className="progress-bar overflow-visible"
                      role="progressbar"
                      aria-label="Red example"
                      style={{ width: "50%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span className="progress-value fw-semibold">50%</span>
                    </div>
                  </div>
                </div>

                <div className="progress-classic position-relative">
                  <div className="progress-label fw-semibold mb-2">UNBOUND</div>
                  <div
                    className="progress overflow-visible"
                    style={{ height: "4px" }}
                  >
                    <div
                      className="progress-bar overflow-visible"
                      role="progressbar"
                      aria-label="Red example"
                      style={{ width: "100%" }}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span className="progress-value fw-semibold">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutContainer;
